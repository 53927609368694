import React from 'react';
import '../App.css';
import { MaterialCommunityIcons, Ionicons } from 'react-web-vector-icons'

class Pets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ava: "https://nashatynda.ru/img/avatars/avatar_512.png",
            dropDownMenuItem: "",
            losts: true,
            onStreet: true,
            settle: false,
            cat: true,
            dog: true,
            female: false,
            male: true,
            base: [{
                sex: "male",
                kind: "dog",
                find: "losts",
                img: "https://static.wixstatic.com/media/48c7c4_94af301bfcaf4ec59ddf3f121672c522.jpg/v1/fill/w_2560,h_1700,al_c,q_85/48c7c4_94af301bfcaf4ec59ddf3f121672c522.webp"
            },
            {
                sex: "female",
                kind: "dog",
                find: "onStreet",
                img: "https://static.wixstatic.com/media/48c7c4_58ad134c655c4515ab88e02ea7939877.jpg/v1/fill/w_333,h_500,al_c,q_85,usm_0.66_1.00_0.01/48c7c4_58ad134c655c4515ab88e02ea7939877.webp"
            },
            {
                sex: "male",
                kind: "cat",
                find: "settle",
                img: "https://static.wixstatic.com/media/48c7c4_fd83c29f06814216a11705077be55924.jpg/v1/fill/w_1280,h_879,al_c,q_85/48c7c4_fd83c29f06814216a11705077be55924.webp"
            },
            {
                sex: "female",
                kind: "cat",
                find: "onStreet",
                img: "https://static.wixstatic.com/media/48c7c4_110867120aa74363996e009166d02370.jpg/v1/fill/w_1280,h_935,al_c,q_85/48c7c4_110867120aa74363996e009166d02370.webp"
            }, {
                sex: "male",
                kind: "dog",
                find: "settle",
                img: "https://static.wixstatic.com/media/48c7c4_4927d9cfc82f4bc78513d99a495e2de0~mv2_d_2000_1331_s_2.jpg/v1/fill/w_2000,h_1331,al_c,q_85/48c7c4_4927d9cfc82f4bc78513d99a495e2de0~mv2_d_2000_1331_s_2.webp"
            }, {
                sex: "male",
                kind: "dog",
                find: "settle",
                img: "https://static.wixstatic.com/media/48c7c4_fd83c29f06814216a11705077be55924.jpg/v1/fill/w_1280,h_879,al_c,q_85/48c7c4_fd83c29f06814216a11705077be55924.webp"
            }, {
                sex: "female",
                kind: "dog",
                find: "settle",
                img: "https://static.wixstatic.com/media/48c7c4_019d66c05db342b9a86f1e97c912cc82~mv2_d_2000_1331_s_2.jpg/v1/fill/w_2000,h_1331,al_c,q_85/48c7c4_019d66c05db342b9a86f1e97c912cc82~mv2_d_2000_1331_s_2.webp"
            }, {
                sex: "female",
                kind: "cat",
                find: "settle",
                img: "https://static.wixstatic.com/media/48c7c4_7569d73ae5c54452bdac577feed87c6c.jpg/v1/fill/w_1280,h_853,al_c,q_85/48c7c4_7569d73ae5c54452bdac577feed87c6c.webp"
            }, {
                sex: "male",
                kind: "cat",
                find: "settle",
                img: "https://static.wixstatic.com/media/48c7c4_6e7169bcbf71407595dabdf3d606a4e3.jpg/v1/fill/w_452,h_375,al_c,q_85,usm_0.66_1.00_0.01/48c7c4_6e7169bcbf71407595dabdf3d606a4e3.webp"
            }, {
                sex: "female",
                kind: "cat",
                find: "onStreet",
                img: "https://static.wixstatic.com/media/48c7c4_2bc848e822d945ea8aec568464888381.jpg/v1/fill/w_1280,h_913,al_c,q_85/48c7c4_2bc848e822d945ea8aec568464888381.webp"
            }, {
                sex: "male",
                kind: "cat",
                find: "onStreet",
                img: "https://static.wixstatic.com/media/48c7c4_90149b135ab7448981ed002c1fd88bc6.jpg/v1/fill/w_1169,h_1024,al_c,q_85/48c7c4_90149b135ab7448981ed002c1fd88bc6.webp"
            }, {
                sex: "male",
                kind: "cat",
                find: "onStreet",
                img: "https://static.wixstatic.com/media/48c7c4_b1d609335f9c416bb67a66bd22da27e0.jpg/v1/fill/w_1280,h_830,al_c,q_85/48c7c4_b1d609335f9c416bb67a66bd22da27e0.webp"
            },
            ]
        }
    }

_renderPet(item, index) {
    console.log(item)
            return(
                <div key={index}>
                    {(this.state[item.kind]&&
                        this.state[item.sex] &&
                        this.state[item.find] )&& 
                    <div className={"cartPet"} style={{ backgroundImage: "url(" + item.img + ")"}}>
                        <div className={"cartPetSex"} >
                            <Ionicons
                                name={'md-'+ item.sex}
                                color={"#fcee21"}
                                size={24}
                            />
                        </div>

                    </div>}
                </div>
            )
      

}

    render() {
        let lostItemColorsLosts = {}
        let lostItemColorsOnStreet = {}
        let lostItemColorsSettle = {}
        let lostItemColorsDog = {}
        let lostItemColorsCat = {}
        let lostItemColorsFemale = {}
        let lostItemColorsMale = {}

        if (this.state.losts) { lostItemColorsLosts = { color: "#333", backgroundColor: "#fcee21"} }
        if (this.state.onStreet) { lostItemColorsOnStreet = { color: "#333", backgroundColor: "#fcee21" } }
        if (this.state.settle) { lostItemColorsSettle = { color: "#333", backgroundColor: "#fcee21" } }
        if (this.state.dog) { lostItemColorsDog = { color: "#333", backgroundColor: "#fcee21" } }
        if (this.state.cat) { lostItemColorsCat = { color: "#333", backgroundColor: "#fcee21" } }
        if (this.state.female) { lostItemColorsFemale = { color: "#333", backgroundColor: "#fcee21" } }
        if (this.state.male) { lostItemColorsMale = { color: "#333", backgroundColor: "#fcee21" } }
        return (
                <div className="App-body">
                    <div className="App-filters">
                    <div className="lost">
                        <div className="lostItem"
                            style={lostItemColorsLosts}
                            onClick={()=>{
                                this.setState({losts: !this.state.losts})
                                if (this.state.losts == true && this.state.onStreet == false && this.state.settle == false) {
                                    this.setState({ onStreet: !this.state.onStreet, settle: !this.state.settle })
                                }
                                }}
                        >Потеряшки</div>
                        <div className="lostItem"
                            style={lostItemColorsOnStreet}
                            onClick={() => {
                                this.setState({ onStreet: !this.state.onStreet })
                                if (this.state.onStreet == true && this.state.losts == false && this.state.settle == false) {
                                    this.setState({ losts: !this.state.losts, settle: !this.state.settle })
                                }
                                }}
                        
                        >Животные на улице</div>
                        <div className="lostItem"
                            style={lostItemColorsSettle}
                            onClick={() => {
                                this.setState({ settle: !this.state.settle })
                                if (this.state.settle == true && this.state.onStreet == false && this.state.losts == false) {
                                    this.setState({ losts: !this.state.losts, onStreet: !this.state.onStreet })
                                }
                                }}
                        
                        >Пристрой</div>
                    </div>
                    <div className="kindFilter">
                        <div className="lostItem"
                            style={lostItemColorsCat}
                            onClick={() => { 
                                this.setState({ cat: !this.state.cat }) 
                                if (this.state.dog == false && this.state.cat == true) {
                                    this.setState({ dog: !this.state.dog })
                                }
                                }}
                        > 
                            <MaterialCommunityIcons
                                name='cat'
                                color={this.state.cat ? '#0f0a41' : '#fffd'}
                                size={24}
                            />
                        </div>
                        <div style={{height: 40, width: 8}}></div>
                        <div className="lostItem"
                            style={lostItemColorsDog}
                            onClick={() => { 
                                this.setState({ dog: !this.state.dog }) 
                                if (this.state.dog == true && this.state.cat == false)
                                {
                                    this.setState({ cat: !this.state.cat }) 
                                }
                                }}
                        >
                            <MaterialCommunityIcons
                                name='dog'
                                color={this.state.dog ? '#0f0a41' : '#fffd'}
                                size={24}
                            />
                        </div>
                       
                    </div>
                    <div className="sexFilter">
                        <div className="lostItem"
                            style={lostItemColorsFemale}
                            onClick={() => { 
                                this.setState({ female: !this.state.female }) 
                                if (this.state.female == true && this.state.male == false) {
                                    this.setState({ male: !this.state.male })
                                }
                                }}
                        >
                            <Ionicons
                                name='md-female'
                                color={this.state.female ? '#0f0a41' : '#fffd'}
                                size={24}
                            />
                        </div>
                        <div style={{ height: 40, width: 8 }}></div>
                        <div className="lostItem"
                            style={lostItemColorsMale}
                            onClick={() => { 
                                this.setState({ male: !this.state.male }) 
                                if (this.state.male == true && this.state.female == false) {
                                    this.setState({ female: !this.state.female })
                                }
                                }}

                        >
                            <Ionicons
                                name='md-male'
                                color={this.state.male ? '#0f0a41' : '#fffd'}
                                size={24}
                            />
                        </div>                      
                    </div>
                    <div> 
                    {/* окрас */}
                    </div>
                    </div>

                    <div className="App-data">
                    {
                        this.state.base.map((item, index)=>this._renderPet(item, index))
                    }
                    <div style={{height: 20, width: "100%"}}></div>
                    </div>
                </div>
        );
    }
}

export default Pets;