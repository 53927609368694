import React from 'react';
import logo from './logo.svg';
import './App.css';
import Pets from './Screens/Pets'
import Slide from 'react-reveal/Slide';
import Flip from 'react-reveal/Flip';
import { slide as Menu } from 'react-burger-menu'
import { MaterialCommunityIcons, Ionicons } from 'react-web-vector-icons'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ava: "https://nashatynda.ru/img/avatars/avatar_512.png",
      dropDownMenuItem: "",
      dropDownMenuItemAnime: "",
      dropDownMenuItemFaq: false,
      dropDownMenuItemAnimeFaq: false,
      dropDownMenuItemPeople: false,
      dropDownMenuItemOrg: false,
      dropDownMenuItemAnimeOrg: false,
      page: "Pets",
      mobMenu: false,
    }
  }

  _dropDownMenu(menuItem){
    let dropDownMenuItem = "dropDownMenuItem" + menuItem
    let dropDownMenuItemAnime = "dropDownMenuItemAnime" + menuItem
    this.setState({ [dropDownMenuItem]: true })
    setTimeout(() => { this.setState({ [dropDownMenuItemAnime]: true }) }, 100)
  }


  _dropCloseMenu(menuItem){
    let dropDownMenuItem = "dropDownMenuItem" + menuItem
    let dropDownMenuItemAnime = "dropDownMenuItemAnime" + menuItem
    this.setState({ [dropDownMenuItemAnime]: false })
    setTimeout(() => { this.setState({ [dropDownMenuItem]: false }) }, 500)
  }

  render(){
  return (
    <div id="outer-container">
      <Menu right Menu width={'50%'} isOpen={this.state.mobMenu} pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        {/* <div id="home" className="menu-item" > Решить</div> */}
        <div className="menu-item-small-red">Я потерял питомца</div>
        <div className="menu-item-small-red">Завести питомца</div>
        <div className="menu-item-small-red">Ветпомощь</div>
        <div className="menu-item-small-red">Помощь специалиста</div>
        <div className="menu-item-small-red">Нужна информация</div>
        <div id="about" className="menu-item" style={{ color: "#fcee21"}}>Животные</div>
        {/* <div id="contact" className="menu-item" >Люди</div> */}
        <div className="menu-item-small-green">Жители</div>
        <div className="menu-item-small-green">Депутаты</div>
        <div className="menu-item-small-green">Волонтёры</div>
        <div className="menu-item-small-green">Специалисты</div>
        {/* <div className="menu-tem">Организации</div> */}
        <div className="menu-item-small-blue">НКО</div>
        <div className="menu-item-small-blue">Власть</div>
        <div className="menu-item-small-blue">Бизнес</div>
      </Menu>
      <div className="App" id="page-wrap">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="Menu" >
          <div className="menuItem"
            onMouseEnter={() => this._dropDownMenu("Faq")}
            onMouseLeave={() => this._dropCloseMenu("Faq")}
            >
            <text className="menuItemText">Решить проблему</text>
            {this.state.dropDownMenuItemFaq &&
              <Flip bottom when={this.state.dropDownMenuItemAnimeFaq}>
              <div className='dropDownMenu'>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Я потерял питомца</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Завести питомца</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Ветпомощь</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Помощь специалиста</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Нужна информация</text>
              </div> 
              </Flip>
            }  
              
          </div>
          <div className="menuItem">
            <text className="menuItemText" style={{ color: "#fcee21" }}>Животные</text>
          </div>

          <div className="menuItem"
            onMouseEnter={() => this._dropDownMenu("People")}
            onMouseLeave={() => this._dropCloseMenu("People")}>
            <div className="menuItemText">Люди</div>
            {this.state.dropDownMenuItemPeople &&
              <Flip bottom when={this.state.dropDownMenuItemAnimePeople}>
              <div className='dropDownMenu'> 
                <text className="menuItemText" style={{marginBottom: 10}}>Жители</text>
                <text className="menuItemText" style={{ marginBottom: 10}}>Депутаты</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Волонтёры</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Специалисты</text>
              </div>
            </Flip>
            }
          </div>
          <div className="menuItem"
            onMouseEnter={() => this._dropDownMenu("Org") }
            onMouseLeave ={() => this._dropCloseMenu("Org")}>
            <div className="menuItemText">Организации</div>
            {this.state.dropDownMenuItemOrg &&
              <Flip bottom when={this.state.dropDownMenuItemAnimeOrg}>
              <div className='dropDownMenu'>
                <text className="menuItemText" style={{ marginBottom: 10 }}>НКО </text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Власть</text>
                <text className="menuItemText" style={{ marginBottom: 10 }}>Бизнес</text>
              </div>
              </Flip>
            }
          </div>
        </div>
        <div>
          <div style={{ backgroundImage: "url(" + this.state.ava +")"}}  className='avatar'></div>
        </div>
        <div onClick={()=>{this.setState({mobMenu: !this.state.mobMenu})}}>

        </div>
      </header>
      <Pets/>
      <footer className="App-footer"></footer>
    </div>
    </div>

  );
  }
}

export default App;